import HttpHandler from "@/handler/HttpHandler";
import HttpConstant from "@/const/HttpConstant";


export default class index9 {
    constructor() {
        this.http = new HttpHandler();
    }

    getToken() {
        let url = '/hwApi/camera/getTokenByAbc';
        return this.http.get(url, null);
    }

    instruction(param, token) {
        const headers = {
            "access-token": token
        }
        let url = '/hw2Api/service/dlapptest__apptest/0.1.0/synGroundLock';
        return this.http.post(url, param, HttpConstant.APPLICATION_JSON_REQ, {
            headers:{
                "access-token": token,
            }
        });
    }
}
