<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-button @click="getToken()">token</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('kTeRxq74m4wNq7k4','147', '1')">147</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('kTeRxq74m4wNq7k4', '147', '0')">147</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('rZKAZcUiww6RfvDW', '148', '1')">148</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('rZKAZcUiww6RfvDW', '148', '0')">148</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('AeFfGHgxWI6G7Fcm', '149', '1')">149</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('AeFfGHgxWI6G7Fcm', '149', '0')">149</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('zAfe5jikSAvtq4wV', '150', '1')">150</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('zAfe5jikSAvtq4wV', '150', '0')">150</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('FyQ97jvTCJJH79bl', '151', '1')">151</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('FyQ97jvTCJJH79bl', '151', '0')">151</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('Xb7PTvVmOQnBo5hr', '152', '1')">152</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('Xb7PTvVmOQnBo5hr', '152', '0')">152</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('E2QLe5srB3UmlZid', '153', '1')">153</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('E2QLe5srB3UmlZid', '153', '0')">153</div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-col :span="12" style="background: red">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('qp3F2V44t6sDfF8W', '154', '1')">154</div>
      </el-col>
      <el-col :span="12" style="background: blue">
        <div style="height: 50px; color: white; font-weight: bold" @click="one('qp3F2V44t6sDfF8W', '154', '0')">154</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import index9 from "@/request/index9.js";

export default {
  name: "test_Index9",
  data() {
    return {
      ask: new index9(),
      token: null
    }
  },
  methods: {
    getToken() {
      this.ask.getToken().then(res => {
        this.token = res.token;
      })
    },
    one(parkingLockNo, truckSpaceNo, parkingLockType) {
      const param = {
        "parkingLockNo": parkingLockNo,
        "kwh": "100",
        "onLine": "1",
        "truckSpaceNo": truckSpaceNo,
        "onHand": "0",
        "parkingLockType": parkingLockType,
        "carNum": parkingLockType == '0' ? "粤A:88888" : ""
      }
      this.ask.instruction(param, this.token)
    }
  },
  mounted() {
    this.getToken();
  }
}
</script>

<style scoped>

</style>
